<template>
  <ByStoreReport />
</template>

<script>
import ByStoreReport from '@/components/pages/sales/ByStoreReport'

export default {
  components: {
    ByStoreReport
  }
}
</script>
