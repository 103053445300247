<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader breadcrumb="Sales" title="By Store Report" />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-9 col-md-10">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <FormDate
                      label="Date From"
                      mask="date"
                      v-model="dateFrom"
                      :value="dateFrom"
                      :rules="[VALIDATION.required]"
                      @input="onSelectFromDate"
                      tabindex="1"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <FormDate
                      label="Date To"
                      mask="date"
                      v-model="dateTo"
                      :value="dateTo"
                      :rules="[VALIDATION.required]"
                      @input="onSelectToDate"
                      tabindex="2"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-3">
                    <SelectStores
                      v-model="stores"
                      :values="stores"
                      @updateStore="$event => (stores = $event)"
                      @getOptions="onSetDefaultStores"
                      tabindex="3"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <SelectCategories
                      v-model="category"
                      :values="category"
                      @updateStore="$event => (category = $event)"
                      tabindex="4"
                      withAll
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <SelectBrands
                      v-model="brand"
                      :values="brand"
                      @updateStore="$event => (brand = $event)"
                      tabindex="5"
                      withAll
                    />
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-3 col-md-2 text-right">
                <Button
                  variant="primary"
                  label="Filter"
                  icon="o_filter_alt"
                  :loading="loadingSales"
                  :disabled="loadingSales"
                  type="submit"
                  tabindex="6"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <Loader :loading="!isFiltered && loadingSales" />

      <NoDataCard v-if="noData" />

      <div class="row items-start q-col-gutter-md" v-if="hasData">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingTable"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
            @onRequest="onRequest"
          />
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    :footer="false"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <div class="p-4">
      <h5 class="q-mb-md">By Store Report</h5>
      <p>
        {{ HELP.BY_STORE_REPORT.description }}
      </p>

      <q-separator class="q-my-md" />

      <h6 class="q-mb-md text-sm">Definition of Terms</h6>
      <Table
        style="max-height: 50vh"
        :rows="HELP.BY_STORE_REPORT.terms"
        :columns="HELP.TABLE"
        :limit="0"
        hide-bottom
      >
        <template v-slot:body="props">
          <q-tr>
            <q-td>
              <strong>{{ props.row.name }}</strong>
            </q-td>
            <q-td :style="{ whiteSpace: 'normal' }">
              {{ props.row.description }}
            </q-td>
          </q-tr>
        </template>
      </Table>
    </div>
  </Modal>
</template>

<script>
import { ref, inject, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { FilterCard, NoDataCard } from '@/components/cards'
import { FormDate } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import Loader from '@/components/Loader'
import {
  SelectCategories,
  SelectBrands,
  SelectStores
} from '@/components/customs'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  getIconUrl,
  fetchData
} from '@/tools'

import { HELP } from '@/constants'

export default {
  name: 'ByStoreReport',
  components: {
    PageHeader,
    PageContent,
    FilterCard,
    NoDataCard,
    FormDate,
    Button,
    Table,
    Modal,
    Loader,
    SelectCategories,
    SelectBrands,
    SelectStores
  },
  setup() {
    const { showToast } = Toast()
    const state = inject('store')?.state

    const {
      data: dataSales,
      error: errorSales,
      loading: loadingSales,
      post: postSales
    } = fetchData()

    const isRetailer = ref(state?.accountType?.retailer)
    const showModal = ref(false)
    const loadingTable = ref(false)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)
    const filterForm = ref(null)
    const dateFrom = ref(
      DATE.toFriendlyDate(
        DATE.toFriendlyFirstDay(DATE.subtractDays(new Date(), 1, 'months'))
      )
    )
    const dateTo = ref(
      DATE.toFriendlyDate(
        DATE.toFriendlyLastDay(DATE.subtractDays(new Date(), 1, 'months'))
      )
    )
    const category = ref('all')
    const brand = ref('all')
    const stores = ref([])
    const salesTableData = ref(null)
    const salesTableHeader = ref([
      {
        name: 'store',
        label: 'Store',
        field: 'store',
        align: 'left',
        sortable: true
      },
      {
        name: 'sales',
        label: 'Sales',
        field: 'sales',
        format: val => FORMAT.toCurrency(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'quantity',
        label: 'Quantity',
        field: 'quantity',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'transCount',
        label: 'Transaction Count',
        field: 'transCount',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'basketSize',
        label: 'Basket Size',
        field: 'basketSize',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )

    const noData = computed(
      () => salesTableData.value && salesTableData.value.length === 0
    )

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onFilter = () => {
      if (!isFiltered.value) {
        limitPage.value = 10
        pageNo.value = 1
        totalCount.value = 0
      }

      filterForm.value.validate().then(success => {
        if (success) {
          const payload = {
            pagination: {
              page: pageNo.value,
              per_page: limitPage.value
            },
            filters: {
              date_from: DATE.toFriendlyDate(dateFrom.value),
              date_to: DATE.toFriendlyDate(dateTo.value),
              stores: stores.value
            }
          }

          if (category.value !== 'all') {
            payload.filters.category = category.value
          }

          if (brand.value !== 'all') {
            payload.filters.brand = brand.value
          }

          if (isRetailer.value) {
            payload.filters.principal = state?.defaultID

            postSales('/v1/retailer/sales/daily-sales/by-store', payload)
          } else {
            payload.filters.retailer = state?.defaultID

            postSales('/v1/sales/daily-sales/by-store', payload)
          }
        } else {
          showToast('Please fill all the required fields', 'info')
        }
      })
    }

    const onSelectFromDate = data => {
      dateFrom.value = data
    }

    const onSelectToDate = data => {
      dateTo.value = data
    }

    const exportTable = () => {
      EXPORT.exportToExcel({
        header: salesTableHeader.value,
        data: salesTableData.value,
        filename: 'by_store_report'
      })
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      onFilter()
    }

    const onSetDefaultStores = data => {
      stores.value = data.map(item => item.value)
    }

    onBeforeMount(() => {
      watch([dataSales, errorSales, loadingSales], () => {
        if (loadingSales.value) {
          if (isFiltered.value) {
            loadingTable.value = true
          } else {
            salesTableData.value = null
          }
        } else {
          if (errorSales?.value) {
            showToast(
              errorSales?.value?.message ??
                'There was a problem fetching sales.',
              'danger'
            )
          } else if (dataSales?.value) {
            totalCount.value = dataSales?.value?.length

            salesTableData.value = dataSales?.value?.map(item => ({
              store: item?.store_name,
              sales: FORMAT.toFixedNumber(item?.sale ?? 0),
              transCount: item?.trx_count ?? 0,
              quantity: item?.quantity ?? 0,
              basketSize: FORMAT.toFixedNumber(item?.basket_size ?? 0)
            }))
          }

          isFiltered.value = false
          loadingTable.value = false
        }
      })
    })

    return {
      HELP,
      showModal,
      loadingSales,
      loadingTable,
      isFiltered,
      limitPage,
      pageNo,
      totalCount,
      filterForm,
      dateFrom,
      dateTo,
      category,
      brand,
      stores,
      salesTableHeader,
      salesTableData,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      onToggleModal,
      onFilter,
      onSelectFromDate,
      onSelectToDate,
      exportTable,
      onRequest,
      onSetDefaultStores
    }
  }
}
</script>
